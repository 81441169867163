import Lenis from 'lenis';
import Pristine from 'pristinejs/dist/pristine';

import { SENDEMAIL_SERVICE } from '../services/sendEmailService';

const HANDLES = {
    init() {
        this.lenisScrollTo();
        this.disableHtmlScroll();
        this.toggleDrawer();
        this.contactRequestADemo();
    },
    lenisScrollTo() {
        const lenis = new Lenis();
        let menuLinks =  document.querySelectorAll('.tb__top-nav a');
        menuLinks.forEach(link => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                document.documentElement.classList.remove('no-scroll');
                lenis.scrollTo(e.currentTarget.getAttribute('href'));
             });
        });
    },
    disableHtmlScroll() {
        let openMenuButton = document.getElementById('open-menu-button');
        let closeMenuButton = document.getElementById('close-menu-button');

        openMenuButton.addEventListener('click', (e) => {
            document.documentElement.classList.add('no-scroll');
        });
        closeMenuButton.addEventListener('click', (e) => {
            document.documentElement.classList.remove('no-scroll');
        });
    },
    toggleDrawer() {
        let drawerContactRequestOpenButton = document.querySelectorAll('button[data-drawer-target="drawer-contact-request-a-demo"]');
        let drawerContactRequestCloseButton = document.querySelector('button[data-drawer-hide="drawer-contact-request-a-demo"]');
        drawerContactRequestOpenButton.forEach(button => {
            button.addEventListener('click', (e) => {
                document.querySelector('.tb__drawer-contact-request-a-demo').classList.remove('hidden');
                drawerContactRequestCloseButton.addEventListener('click', (e) => {
                    document.querySelector('.tb__drawer-contact-request-a-demo').classList.add('hidden');
                });
            });
        });
    },
    contactRequestADemo() {
        const form = document.getElementById('form-contact-request-a-demo');
        const pristine = new Pristine(form);
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            let valid = pristine.validate();
            if (valid) {
                // add spinner
                e.currentTarget.querySelector('button[type="submit"] svg').classList.remove('hidden');
                return SENDEMAIL_SERVICE.contactRequestADemo(form);
            }
        });
    }
}

export { HANDLES }