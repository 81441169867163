import { initFlowbite } from 'flowbite';

import { UTILS } from './../utils/utils';

const SENDEMAIL_SERVICE = {
    async contactRequestADemo(form) {
        emailjs.init({
            publicKey: process.env.EJS_API_KEY,
        });
        emailjs.sendForm(process.env.EJS_SERVICE_ID, process.env.EJS_TEMPLATE_ID, '#form-contact-request-a-demo')
            .then((response) => {
                initFlowbite();
                form.reset();
                form.querySelector('button[type="submit"] svg').classList.add('hidden');
                UTILS.toast('#18443C', 'Thank you! Your request has been successfully submitted.');
                console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
                form.querySelector('button[type="submit"] svg').classList.add('hidden');
                UTILS.toast('#B52E51', `${error}`);
                console.log('FAILED...', error);
            });
    }
}

export { SENDEMAIL_SERVICE }