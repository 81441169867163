/* Import CSS Plugins and main SCSS */
import 'animate.css/animate.min.css';
import 'aos/dist/aos.css';
import 'glightbox/src/postcss/glightbox.css';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

/* Import Js Plugins (ES6 Module) and custom JS */
import AOS from 'aos';
import Rellax from 'rellax';
import GLightbox from 'glightbox';
import Lenis from 'lenis';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ActiveMenuLink from 'active-menu-link';
import {  Modal } from 'flowbite';

gsap.registerPlugin(ScrollTrigger);

import '../plugins/cookieconsent-init';

/* Global variables */
const sm = window.matchMedia('(max-width: 767px)');
// const md = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
// const lg = window.matchMedia('(min-width: 1024px) and (max-width: 1279px)');
let lastScroll = 0;
let delta = 5;
let headerHeight = document.querySelector('header').offsetHeight;

const UTILS = {
    init() {
        AOS.init();
        new Rellax('.rellax');
        GLightbox({
            touchNavigation: true,
            autoplayVideos: true
        });
        this.lenisScroll();
        this.activeMenuLink();
        this.topNavigationMenu();
        this.heroAnimation();
        this.bodyBgAnimation();
        this.benefitsAnimation();
        this.tokenBenefitsAnimation();
        this.copyPrintYear();
    },
    lenisScroll() {
        const lenis = new Lenis({
            duration: 1.5
        });

        lenis.on('scroll', (e) => {
            // console.log(e)
        })
        lenis.on('scroll', ScrollTrigger.update);

        gsap.ticker.add((time) => {
            lenis.raf(time * 1000)
        });
        gsap.ticker.lagSmoothing(0);
    },
    activeMenuLink() {
        let options = {
            //showHash: false,
        };
        new ActiveMenuLink('.tb__top-nav', options);
    },
    topNavigationMenu() {
        let toggleMenuButton = document.querySelector('#toggle-menu-button');
        let menu = document.querySelector('#toggle-menu');
        let openMenuButton = document.querySelector('#open-menu-button');
        let closeMenuButton = document.querySelector('#close-menu-button');
        let itemMenu = document.querySelectorAll('#toggle-menu a');

        toggleMenuButton.addEventListener('click', (e) => {
            menu.classList.toggle('hidden');
            openMenuButton.classList.toggle('hidden');
            closeMenuButton.classList.toggle('hidden');
        });
        itemMenu.forEach((el, index) => {
            el.addEventListener('click', (e) => {
                e.preventDefault()
                toggleMenuButton.click();
            });
        });
    },
    headerAnimation() {
        let scrollTop = document.documentElement.scrollTop
        if (Math.abs(lastScroll - scrollTop) <= delta)
            return;
        if (scrollTop > lastScroll && scrollTop > headerHeight) {
            // scroll down
            document.querySelector('header').classList.remove('down');
            document.querySelector('header').classList.add('up');
        } else {
            // scroll up
            if (scrollTop + window.innerHeight < document.documentElement.scrollHeight) {
                document.querySelector('header').classList.remove('up');
                document.querySelector('header').classList.add('down');
            }
            if (scrollTop <= 50) {
                document.querySelector('header').classList.remove('down');
            }
        }
        lastScroll = scrollTop;
    },
    heroAnimation() {
        let tl = gsap.timeline();
        if (sm.matches) {
            tl.to('.tb__hero_screens-grid', { display: 'block', height: '300px', scale: 1, duration: 0.6, delay: 2, ease: 'power1.inOut' })
                .to('.tb__hero_screens-grid', { opacity: 1, duration: 0.2, delay: 1.3, ease: 'power1.inOut' }, 1)
                .to('.tb__hero-text-1', { scale: '0.9', y: 20, duration: 0.6, delay: 1.4, ease: 'power1.inOut' }, 1)
                .to('.tb__hero-text-2', { scale: '0.9', y: -20, duration: 0.6, delay: 1.4, ease: 'power1.inOut' }, 1);
        } else {
            tl.to('.tb__hero_screens-grid', { display: 'block', height: '392px', scale: 1, duration: 0.6, delay: 2, ease: 'power1.inOut' })
                .to('.tb__hero_screens-grid', { opacity: 1, duration: 0.2, delay: 1.3, ease: 'power1.inOut' }, 1)
                .to('.tb__hero-text-1', { scale: '0.9', y: 20, duration: 0.6, delay: 1.4, ease: 'power1.inOut' }, 1)
                .to('.tb__hero-text-2', { scale: '0.9', y: -20, duration: 0.6, delay: 1.4, ease: 'power1.inOut' }, 1);
        }
    },
    bodyBgAnimation() {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.tb__about-us',
                start: 'top center',
                end: '+=200',
                scrub: true,
                //markers: true
            }
        });
        tl.addLabel('start bodyBgAnimation')
            .to('.tb__hero', { background: 'none', duration: 1 })
            .to('body', { backgroundColor: '#F9F8F2', duration: 1 })
            .addLabel('end bodyBgAnimation');
    },
    benefitsAnimation() {
        if (! sm.matches) {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.mb__benefits-wrap',
                    pin: true,
                    start: '-=120',
                    end: '+=4800',
                    scrub: true,
                    //markers: true
                }
            });

            tl.addLabel('start benefitsAnimation')
                .to('.mb__benefits-board', { opacity: 1 })
                .to('.mb__benefits-board-title-1', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 0)
                .to('.mb__benefits-board-subtitle-1', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 0)
                .to('.mb__benefits-board-image-1', { display: 'block', opacity: 1, scale: 1, duration: 0.6, ease: 'power1.inOut' }, 0)
                .to('.mb__benefits-track-1', { width: '102%', duration: 0.9, ease: 'power1.inOut' }, 0)

                .to('.mb__benefits-track-point-1', { display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 0)
                .to('.mb__benefits-track-point-2', { display: 'block', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 0)
                .to('.mb__benefits-board-title-1', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 0)
                .to('.mb__benefits-board-subtitle-1', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 0)
                .to('.mb__benefits-board-image-1', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 0)

                .to('.mb__benefits-board-title-2', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 1)
                .to('.mb__benefits-board-subtitle-2', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 1)
                .to('.mb__benefits-board-image-2', { display: 'block', opacity: 1, scale: 1, duration: 0.6, ease: 'power1.inOut' }, 1)
                .to('.mb__benefits-track-2', { width: '102%', duration: 0.9, ease: 'power1.inOut' }, 1)

                .to('.mb__benefits-track-point-2', { display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 1)
                .to('.mb__benefits-track-point-3', { display: 'block', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 1)
                .to('.mb__benefits-board-title-2', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 1)
                .to('.mb__benefits-board-subtitle-2', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 1)
                .to('.mb__benefits-board-image-2', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 1)

                .to('.mb__benefits-board-title-3', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 2)
                .to('.mb__benefits-board-subtitle-3', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 2)
                .to('.mb__benefits-board-image-3', { display: 'block', opacity: 1, scale: 1, duration: 0.6, ease: 'power1.inOut' }, 2)
                .to('.mb__benefits-track-3', { width: '102%', duration: 0.9, ease: 'power1.inOut' }, 2)

                .to('.mb__benefits-track-point-3', { display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 2)
                .to('.mb__benefits-track-point-4', { display: 'block', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 2)
                .to('.mb__benefits-board-title-3', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 2)
                .to('.mb__benefits-board-subtitle-3', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 2)
                .to('.mb__benefits-board-image-3', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 2)

                .to('.mb__benefits-board-title-4', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 3)
                .to('.mb__benefits-board-subtitle-4', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 3)
                .to('.mb__benefits-board-image-4', { display: 'block', opacity: 1, scale: 1, duration: 0.6, ease: 'power1.inOut' }, 3)
                .to('.mb__benefits-track-4', { width: '102%', duration: 0.9, ease: 'power1.inOut' }, 3)

                .to('.mb__benefits-track-point-4', { display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 3)
                .to('.mb__benefits-track-point-5', { display: 'block', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 3)
                .to('.mb__benefits-board-title-4', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 3)
                .to('.mb__benefits-board-subtitle-4', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 3)
                .to('.mb__benefits-board-image-4', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 3)

                .to('.mb__benefits-board-title-5', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 4)
                .to('.mb__benefits-board-subtitle-5', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 4)
                .to('.mb__benefits-board-image-5', { display: 'block', opacity: 1, scale: 1, duration: 0.6, ease: 'power1.inOut' }, 4)
                .to('.mb__benefits-track-5', { width: '102%', duration: 0.9, ease: 'power1.inOut' }, 4)

                .to('.mb__benefits-track-point-5', { display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 4)
                .to('.mb__benefits-track-point-6', { display: 'block', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 4)
                .to('.mb__benefits-board-title-5', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 4)
                .to('.mb__benefits-board-subtitle-5', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 4)
                .to('.mb__benefits-board-image-5', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 4)

                .to('.mb__benefits-board-title-6', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 5)
                .to('.mb__benefits-board-subtitle-6', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 5)
                .to('.mb__benefits-board-image-6', { display: 'block', opacity: 1, scale: 1, duration: 0.6, ease: 'power1.inOut' }, 5)
                .to('.mb__benefits-track-6', { width: '102%', duration: 0.9, ease: 'power1.inOut' }, 5)

                .to('.mb__benefits-track-point-6', { display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 5)
                .to('.mb__benefits-track-point-7', { display: 'block', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 5)
                .to('.mb__benefits-board-title-6', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 5)
                .to('.mb__benefits-board-subtitle-6', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 5)
                .to('.mb__benefits-board-image-6', { opacity: 0, display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 5)

                .to('.mb__benefits-board-title-7', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 6)
                .to('.mb__benefits-board-subtitle-7', { display: 'block', opacity: 1, bottom: 0, duration: 0.5, ease: 'power1.inOut' }, 6)
                .to('.mb__benefits-board-image-7', { display: 'block', opacity: 1, scale: 1, duration: 0.6, ease: 'power1.inOut' }, 6)
                .to('.mb__benefits-track-7', { width: '100%', duration: 0.9, ease: 'power1.inOut' }, 6)

                .to('.mb__benefits-track-point-6', { display: 'none', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 6)
                .to('.mb__benefits-track-point-7', { display: 'block', delay: 0.9, duration: 0.1, ease: 'power1.inOut' }, 6)

                .addLabel('end benefitsAnimation');
        }
    },
    tokenBenefitsAnimation() {
        if (! sm.matches) {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.mb__token-benefits-wrap',
                    pin: true,
                    start: '-=200',
                    end: '+=1500',
                    scrub: true,
                    //markers: true
                }
            });

            tl.addLabel('start tokenBenefitsAnimation')
                .to('.tb__token-benefit-1', { display: 'block', delay: 10, duration: 4 })
                .to('.tb__token-benefit-2', { display: 'block', delay: 20, duration: 4 })
                .to('.tb__token-benefit-3', { display: 'block', delay: 30, duration: 4 })
                .to('.mb__token-benefits-wrap', { opacity: 1, delay: 40, duration: 4 })
                .addLabel('end tokenBenefitsAnimation');
        }
    },
    copyPrintYear() {
        const year = new Date().getFullYear();
        document.querySelector('footer .year').innerText = year;
    },
    toast(statusBg, message) {
        document.querySelector('.wrap-toast').innerHTML += `
            <div class="text-center text-white fixed bottom-5 px-4 py-3 rounded-lg z-50 animate__animated animate__fadeInUp" style="background-color: ${statusBg}">
                ${message}
            </div>
        `;
        setTimeout(() => {
            document.querySelector('.wrap-toast div').remove();
        }, 3000);
    }
}

export { UTILS }